import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { useAuth0 } from "@auth0/auth0-react";
import { assignVersion } from "../utils/utils";
import { FaInstagram, FaFacebookF, FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { ChevronDown } from "lucide-react";

const TopSection = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  const isDesktop = screenSize >= 768;

  return (
    <div className={`bg-[#fff] p-5 ${isDesktop ? "mt-24" : "mt-[-20px]"}`}>
      <div
        className={`space-y-8 ${
          isDesktop ? "text-left" : "text-center"
        } p-3 pt-[20px] bg-[#fff] ${
          isDesktop ? "max-w-[1200px]" : "max-w-[414px]"
        } mx-auto`}
      >
        <h2
          className="regular max-w-[600px]"
          style={{
            fontSize: isDesktop ? "48px" : "25px",
            lineHeight: isDesktop ? "1.2" : "normal",
          }}
        >
          Get answers. Find inspiration. Be more productive.
          <br />
        </h2>

        <div
          className={`${isDesktop ? "justify-start" : "justify-center"} flex`}
        >
          <button
            onClick={onClick}
            className="bg-[#0CA37F] text-white px-6 py-5 rounded-[4px] text-[15px] w-full md:w-auto md:min-w-[220px] flex items-center justify-center space-x-2 regular"
          >
            <span>Try GPT-4o</span>
            <span className="text-white">→</span>
          </button>
        </div>

        {/* <div className={`flex items-center ${isDesktop ? 'justify-start' : 'justify-center'} space-x-2`}>
          <div className="flex -space-x-2">
            <img
              src="https://storage.googleapis.com/glowupthenblowup.appspot.com/woman_1.png"
              alt="User 1"
              className="rounded-full border-2 w-8 h-8 md:w-10 md:h-10"
            />
            <img
              src="https://storage.googleapis.com/glowupthenblowup.appspot.com/person2.png"
              alt="User 2"
              className="rounded-full border-2 w-8 h-8 md:w-10 md:h-10"
            />
            <img
              src="https://storage.googleapis.com/glowupthenblowup.appspot.com/woman_2.png"
              alt="User 3"
              className="rounded-full border-2 w-8 h-8 md:w-10 md:h-10"
            />
          </div>
          <span className={`${isDesktop ? 'text-[22px]' : 'text-[16px]'} regular`}>
            Loved by over 100 million people
          </span>
        </div> */}

        <p
          className={`${
            isDesktop ? "text-[24px] text-left" : "text-[17px] text-center"
          } regular ${isDesktop ? "px-0" : "px-6"} max-w-2xl ${
            isDesktop ? "mx-0" : "mx-auto"
          }`}
        >
          Free and easy to try. Just ask and GPT-4o can help with writing,
          learning, brainstorming, and more.
        </p>

        {isDesktop && (
          <div
            className={`w-full overflow-hidden py-8 ${isDesktop ? "mt-6" : ""}`}
          >
            <div className="flex animate-scroll">
              {[
                "Write a poem",
                "Explain quantum physics",
                "Plan a vacation",
                "Debug my code",
                "Summarize a book",
                "Create a meal plan",
                "Translate a phrase",
                "Solve a math problem",
                "Generate a business idea",
                "Explain historical events",
                "Write a song",
                "Analyze market trends",
                "Design a workout routine",
                "Brainstorm marketing strategies",
                "Explain scientific concepts",
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex-shrink-0 w-64 h-24 mx-4 bg-[#F7F7F7] rounded-[4px] flex items-center justify-center text-center p-4"
                >
                  <p className="text-gray-800 font-medium">{item}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        <style>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
        .animate-scroll {
          display: flex;
          animation: scroll 40s linear infinite;
          width: 200%;
        }
      `}</style>
      </div>
    </div>
  );
};

const Section1 = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  const isDesktop = screenSize >= 768;

  return (
    <div className={`font-sans ${isDesktop ? "mt-24" : ""}`}>
      <div className="bg-[#fff] p-6 pt-2 text-black text-center">
        <h2
          className="font-bold mb-8 leading-tight regular"
          style={{
            fontSize: screenSize < 414 ? "25px" : "28px",
          }}
        >
          Writes, brainstorms, edits, and explores ideas with you
        </h2>

        <img
          src="https://storage.googleapis.com/glowupthenblowup.appspot.com/gpt_1-min.png"
          className="w-318 mb-2 mt-6"
        />
      </div>
      {/* Blue section */}
      <div className="bg-[#fff] p-6 pt-2 text-black text-center">
        <h2
          className="font-bold mb-8 leading-tight regular"
          style={{
            fontSize: screenSize < 414 ? "25px" : "28px",
          }}
        >
          Summarize meetings. Find new insights. Increase productivity.
        </h2>

        <img
          src="https://storage.googleapis.com/glowupthenblowup.appspot.com/gpt_2-min.png"
          className="w-318 mb-2 mt-6"
        />
      </div>
    </div>
  );
};

const Section2 = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="font-sans text-black">
      <div className="font-sans">
        {/* Blue section */}
        <div className="bg-[#fff] p-6 pt-2 text-black text-center">
          <h2
            className="font-bold mb-8 leading-tight regular"
            style={{
              fontSize: screenSize < 414 ? "25px" : "28px",
            }}
          >
            Generate and debug code. Automate repetitive tasks. Learn new APIs.
          </h2>

          <img
            src="https://storage.googleapis.com/glowupthenblowup.appspot.com/gpt_3-min.png"
            className="w-318 mb-2 mt-6"
          />
        </div>
      </div>
    </div>
  );
};

const Section3 = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  return (
    <div className="font-sans text-black">
      {/* Black section */}
      <div className="bg-white p-8 pt-4 pb-2 text-center">
        <h2
          className="font-bold mb-8 leading-tight regular"
          style={{
            fontSize: screenSize < 414 ? "25px" : "28px",
          }}
        >
          Try GPT-4o for free today.
        </h2>

        {/* Sign Up button */}
        <button
          onClick={onClick}
          className="bg-[#0CA37F] text-white font-bold py-4 px-8 rounded-[4px] text-[15px] mb-8 regular  w-full md:w-auto md:min-w-[220px]"
        >
          Try Now <span className="text-gray-500"></span>
        </button>
      </div>
    </div>
  );
};

const Section4 = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  const testimonials = [
    {
      name: "Zach Nelson",
      handle: "@zachnelso19",
      date: "18 June",
      content:
        "GPT-4o is a game-changer! It helps me brainstorm ideas for my work projects in minutes. The AI's ability to understand context and provide relevant suggestions is impressive. It's like having a brilliant assistant available 24/7.",
      imageURL:
        "https://storage.googleapis.com/glowupthenblowup.appspot.com/zach.jpg",
    },
    {
      name: "Luke Gilford",
      handle: "@lukegilford",
      date: "23 June",
      content:
        "I was skeptical at first, but GPT-4o exceeded my expectations. It's incredibly helpful for writing and editing. Whether I'm crafting emails or working on reports, it always provides valuable insights and improvements.",
      imageURL:
        "https://storage.googleapis.com/glowupthenblowup.appspot.com/luke.jpg",
    },
    {
      name: "Sabrina Mayes",
      handle: "@sabrina___",
      date: "20 Mar",
      content:
        "GPT-4o has become my go-to tool for learning. It explains complex topics in simple terms and helps me grasp new concepts quickly. It's like having a patient tutor available whenever I need one. Absolutely love it!",
      imageURL:
        "https://storage.googleapis.com/glowupthenblowup.appspot.com/sabrina.jpg",
    },
    {
      name: "Lucy Hormozi",
      handle: "@lhormozi19",
      date: "1 Jul",
      content:
        "As a content creator, GPT-4o has been invaluable. It helps me overcome writer's block, generates creative ideas, and even assists with research. It's boosted my productivity significantly. Can't imagine working without it now!",
      imageURL:
        "https://storage.googleapis.com/glowupthenblowup.appspot.com/lucy.jpg",
    },
  ];

  return (
    <div className="bg-[#fff] text-black p-8 font-sans">
      <div className="max-w-2xl mx-auto">
        <h2
          className="font-bold mb-8 leading-tight regular text-center"
          style={{
            fontSize: screenSize < 414 ? "25px" : "28px",
          }}
        >
          Join 100 million people using GPT-4o every day.
        </h2>
        <div className="bg-white rounded-lg p-4 shadow-lg mb-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="mb-6 last:mb-0">
              <div className="flex items-center text-gray-600 text-sm">
                <img
                  src={testimonial.imageURL}
                  alt={testimonial.name}
                  className="w-8 h-8 rounded-full mr-2"
                />
                <span className="font-bold">{testimonial.name}</span>
                <span className="ml-2">{testimonial.handle}</span>
                {/* <span className="ml-2">· {testimonial.date}</span> */}
              </div>
              <p className="mt-1 text-gray-800">{testimonial.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Section7 = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  return (
    <div className="font-sans text-black">
      {/* Black section */}
      <div className="bg-white p-6 pt-6 text-center">
        <h2
          className="font-bold mb-8 leading-tight regular"
          style={{
            fontSize: screenSize < 414 ? "25px" : "28px",
          }}
        >
          Get Started with GPT-4o
        </h2>

        {/* Sign Up button */}
        <button
          onClick={onClick}
          className="bg-[#0CA37F] text-white font-bold py-4 px-8 rounded-[4px] text-[14px] mb-8 regular  w-full md:w-auto md:min-w-[220px]"
        >
          Join GPT-4o <span className="text-gray-500"></span>
        </button>
      </div>
    </div>
  );
};

const FAQItem = ({
  question,
  children,
}: {
  question: string;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-gray-200 rounded-md mb-4">
      <button
        className="w-full text-left p-4 flex justify-between items-center focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium">{question}</span>
        <ChevronDown
          className={`transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isOpen && <div className="p-4 pt-0 text-gray-600">{children}</div>}
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    { question: "What is GPT-4o?" },
    { question: "How can GPT-4o help me?" },
    {
      question: "Is GPT-4o available in multiple languages?",
    },
    {
      question: "How accurate is GPT-4o?",
    },
    {
      question: "Can I use GPT-4o for professional or academic purposes?",
    },
    {
      question: "Are there different subscription plans for GPT-4o?",
    },
    { question: "Is there a free version of GPT-4o?" },
    {
      question: "How is my privacy protected when using GPT-4o?",
    },
  ];

  const answers = [
    "GPT-4o is an advanced AI language model developed by OpenAI. It's designed to understand and generate human-like text, providing responses to a wide range of questions and assisting with various tasks.",
    "GPT-4o can help you with writing, answering questions, problem-solving, brainstorming ideas, explaining complex topics, and much more. It's a versatile tool that can assist in numerous personal and professional tasks.",
    "Yes, GPT-4o is capable of understanding and generating text in multiple languages. However, its proficiency may vary depending on the language and the specific version of the model being used.",
    "GPT-4o's accuracy can be quite high, especially for general knowledge and common tasks. However, it's important to note that it can sometimes make mistakes or provide outdated information. It's always a good idea to verify important information from authoritative sources.",
    "Absolutely! GPT-4o can be a valuable tool for both professional and academic purposes. It can help with research, writing, coding, problem-solving, and more. However, it's important to use it as a supportive tool and not rely on it entirely, especially for critical or specialized tasks.",
    "Yes, OpenAI offers different subscription plans for GPT-4o, including a free tier and a premium subscription called GPT-4o Plus. The premium plan offers benefits like faster response times, priority access during peak times, and access to new features.",
    "Yes, there is a free version of GPT-4o available to the public. While it may have some limitations compared to the paid version, it still offers a wide range of capabilities and can be incredibly useful for many tasks.",
    "OpenAI takes privacy seriously. They don't use your conversations to train their models, and they have strict data handling policies. However, it's always advisable to avoid sharing sensitive personal information in your conversations with GPT-4o.",
  ];

  return (
    <div className="bg-white text-black p-6">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-center regular">
          Frequently Asked Questions
        </h1>
        <p className="text-center mb-8 text-gray-600">
          Contact us at{" "}
          <a
            href="mailto:hello@imimim.co"
            className="text-blue-600 hover:underline"
          >
            hello@imimim.co{" "}
          </a>
          for any additional queries and concerns
        </p>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question}>
            <p>{answers[index]}</p>
          </FAQItem>
        ))}
      </div>
    </div>
  );
};

const Landing = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const hasPaid = localStorage.getItem("hasPaid") === "true";
  const [version, setVersion] = useState<string>("");
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    // Check if userId exists in localStorage
    let userId = localStorage.getItem("userId");

    // If userId doesn't exist, create a new one
    if (!userId) {
      userId = generateUserId();
      localStorage.setItem("userId", userId);
      localStorage.setItem("hasPaid", "false");
    }

    const version = assignVersion();
    setVersion(version);
    localStorage.setItem("version", version);

    // Add event listener for window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const generateUserId = () => {
    // Generate a random userId (you can use any method you prefer)
    return Math.random().toString(36).substr(2, 9);
  };

  const { loginWithRedirect } = useAuth0();

  const handleClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Sign Up",
    });

    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    if (isLoggedIn) {
      navigate("/chat");
    }
  }, []);

  return (
    <div className="bg-white min-h-screen font-sans text-black">
      <div className="max-w-[1200px] mx-auto">
        {/* Header */}
        <div
          className={`flex ${
            screenSize < 768 ? "justify-center" : "justify-between"
          } p-6 items-center bg-[#fff]`}
        >
          <div
            className={`flex items-center ${
              screenSize < 768 ? "justify-center" : "justify-between"
            } w-full`}
          >
            <div className="flex items-center space-x-2">
              <img
                src="https://storage.googleapis.com/glowupthenblowup.appspot.com/im_logo_dark_green.png"
                alt=""
                className={`mr-2 ${
                  screenSize < 414 ? "w-[44px] h-[44px]" : "w-[51px] h-[51px]"
                }`}
              />
              <div className="flex flex-col">
                <h1
                  className="font-bold regular"
                  style={{
                    fontSize: screenSize < 414 ? "22px" : "25px",
                  }}
                >
                  iM
                </h1>
                <span className="text-xs text-gray-500">Powered by OpenAI</span>
              </div>
            </div>
            {screenSize >= 768 && (
              <button
                onClick={handleClick}
                className="bg-[#0CA37F] text-white px-4 py-2 rounded-[4px] text-[15px] regular"
              >
                Sign Up
              </button>
            )}
          </div>
        </div>

        <TopSection screenSize={screenSize} onClick={handleClick} />
        <Section1 screenSize={screenSize} onClick={handleClick} />
        <Section2 screenSize={screenSize} onClick={handleClick} />
        <Section3 screenSize={screenSize} onClick={handleClick} />
        <Section4 screenSize={screenSize} onClick={handleClick} />
        <Section7 screenSize={screenSize} onClick={handleClick} />
        <FAQSection />

        {/* <div className="flex justify-center p-4 pt-2 pb-2 space-x-8 mb-6 mt-4">
          <a
            href="https://www.instagram.com/RobinhoodApp/"
            className="w-20 h-20 rounded-full flex items-center justify-center"
          >
            <FaInstagram className="text-white text-3xl" />
          </a>
          <a
            href="https://www.facebook.com/RobinhoodApp/"
            className="w-20 h-20 rounded-full flex items-center justify-center"
          >
            <FaFacebookF className="text-white text-3xl" />
          </a>
          <a
            href="https://twitter.com/RobinhoodApp"
            className="w-20 h-20 rounded-full flex items-center justify-center"
          >
            <FaXTwitter className="text-white text-3xl" />
          </a>
        </div> */}
        <p className="text-sm text-gray-500 text-center p-8">
          Copyright 2024 iM. All rights reserved.
          <br />
        </p>
      </div>
    </div>
  );
};

export default Landing;
