import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import './styles/index.css';
import App from './App';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-42RVJ374HJ');

const root = createRoot(document.getElementById('root') as HTMLElement);

const isDev = process.env.NODE_ENV === 'development';

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="jenni.us.auth0.com"
      clientId="sKbdU2UHmSXx0cOJUmE5axAkOwMyvnef"
      authorizationParams={{
        redirect_uri: isDev ? 'http://localhost:3000/pricing' : `https://imimim.co/pricing`,
      }}
    >
        <App />
    </Auth0Provider>
  </React.StrictMode>
);
