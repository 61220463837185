import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [version, setVersion] = React.useState<string>("");

  useEffect(() => {
    const storedVersion = localStorage.getItem("version") || "";
    setVersion(storedVersion);
    if (!storedVersion) {
      console.warn("Version not found in localStorage");
    }

    navigate("/");
  }, [navigate]);

  return (
    <div className="bg-white text-black min-h-screen flex items-center justify-center"></div>
  );
};

export default Login;
