import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Success from "./pages/Success";
import Failure from "./pages/Failure";
import Glow from "./pages/Glow";
import Play from "./pages/Play";
import Upload from "./pages/Upload";
import Landing from "./pages/Landing";
import RootdLanding from "./pages/RootdLanding";
import MemzLanding from "./pages/MemzLanding";
import MemzPricing from "./pages/MemzPricing";
import Login from "./pages/Login";
import Pricing from "./pages/Pricing";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import {
  Send,
  Settings,
  Paperclip,
  Plus,
  User,
  ArrowUp,
  X,
} from "lucide-react";
import Chat from "./pages/Chat";

ReactGA.initialize("G-FB9WJV50TM");

// Profile/Settings Page
const ProfileSettings: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Profile & Settings</h1>
      <div className="space-y-4">
        <div>
          <h2 className="text-xl font-semibold mb-2">Personal Information</h2>
          <input
            type="text"
            placeholder="Name"
            className="w-full p-2 border rounded"
          />
          <input
            type="email"
            placeholder="Email"
            className="w-full p-2 border rounded mt-2"
          />
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-2">Preferences</h2>
          <label className="flex items-center">
            <input type="checkbox" className="mr-2" />
            Receive email notifications
          </label>
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-2">Account</h2>
          <button
            onClick={() => navigate("/manage")}
            className="text-blue-500 hover:underline"
          >
            Manage Subscription
          </button>
        </div>
      </div>
    </div>
  );
};

// Manage Page
const Manage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Manage Subscription</h1>
      <div className="space-y-4">
        <div>
          <h2 className="text-xl font-semibold mb-2">Current Plan</h2>
          <p>You are currently on the Pro plan</p>
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-2">Billing Information</h2>
          <p>Next billing date: 01/01/2025</p>
        </div>
        <div>
          <button
            onClick={() => navigate("/cancel-reason")}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Cancel Subscription
          </button>
        </div>
      </div>
    </div>
  );
};

// Cancellation Reason Form Page
const CancellationReasonForm: React.FC = () => {
  const [reason, setReason] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    // Here you would typically send the reason to your backend
    console.log("Cancellation reason:", reason);
    // Navigate to the final cancellation page
    navigate("/cancel-confirmation");
  };

  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="h-screen bg-white flex flex-col">
      {/* Chat header */}
      <div className="bg-white shadow-sm p-4 flex items-center justify-between">
        <h1 className="text-xl regular">ChatGPT 4o</h1>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => navigate("/chat")}
            className="p-2 bg-gray-200 text-gray-600 rounded-full hover:bg-gray-300 focus:outline-none"
          >
            <Plus size={20} />
          </button>
          <button
            onClick={() => navigate("/chat")}
            className="p-2 bg-gray-200 text-gray-600 rounded-full hover:bg-gray-300 focus:outline-none"
          >
            <User size={20} />
          </button>
        </div>
      </div>
      <div className="flex-grow flex items-center justify-center mt-[-40px]">
        <div
          className="p-6 max-w-2xl mx-auto bg-white shadow-lg rounded-lg"
          style={{ minWidth: "375px" }}
        >
          {/* <h1 className="text-md regular mb-6 text-left">
            We're sorry to see you go
          </h1> */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="reason"
                className="block text-sm mb-2 text-left font-medium text-gray-700"
              >
                Please tell us why you're cancelling:
              </label>
              <textarea
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-[#0CA37F] focus:border-[#0CA37F]"
                rows={4}
                required
              />
            </div>
            <div className="flex justify-start">
              <button
                type="submit"
                className="text-sm bg-[#0CA37F] text-white px-4 py-2 rounded-md hover:bg-[#0B8E6F] transition duration-300 ease-in-out"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// Final Cancellation Confirmation Page
const CancellationConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleFinalCancellation = () => {
    // Here you would typically send the final cancellation request to your backend
    console.log("Subscription cancelled");
    alert("Your subscription has been cancelled.");
    // Redirect to home page or show a final message
    localStorage.setItem("hasPaid", "false");
    localStorage.setItem("isLoggedIn", "false");
    navigate("/");
  };

  return (
    <div className="h-screen bg-white flex flex-col">
      {/* Chat header */}
      <div className="bg-white shadow-sm p-4 flex items-center justify-between">
        <h1 className="text-xl regular">ChatGPT 4o</h1>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => navigate("/chat")}
            className="p-2 bg-gray-200 text-gray-600 rounded-full hover:bg-gray-300 focus:outline-none"
          >
            <Plus size={20} />
          </button>
          <button
            onClick={() => navigate("/chat")}
            className="p-2 bg-gray-200 text-gray-600 rounded-full hover:bg-gray-300 focus:outline-none"
          >
            <User size={20} />
          </button>
        </div>
      </div>
      <div className="flex-grow flex items-center justify-center mt-[-40px]">
        <div className="p-6 max-w-2xl min-w-[400px] bg-white shadow-lg rounded-lg border border-gray-200">
          <p className="mb-4 text-left text-lg regular">Are you sure you want to cancel?</p>
          <div className="flex flex-col items-start space-y-4">
            <button
              onClick={() => navigate("/chat")}
              className="text-sm bg-[#0CA37F] text-white px-4 py-2 rounded-md hover:bg-[#0B8E6F] transition-colors duration-200 w-full"
            >
              No, Keep My Subscription
            </button>
            <button
              onClick={handleFinalCancellation}
              className="text-sm text-[#0CA37F] hover:underline w-full text-left hover:text-[#0B8E6F] transition-colors duration-200"
            >
              Yes, Cancel My Subscription
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-white text-black">
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<MemzLanding />} />
            <Route path="/home" element={<MemzLanding />} />
            <Route path="/pricing" element={<MemzPricing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Login />} />
            <Route path="/play/:id" element={<Play />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/success" element={<Success />} />
            <Route path="/failure" element={<Failure />} />
            <Route path="/profile" element={<ProfileSettings />} />
            <Route path="/settings" element={<ProfileSettings />} />
            <Route path="/manage" element={<Manage />} />
            <Route path="/cancel-reason" element={<CancellationReasonForm />} />
            <Route
              path="/cancel-confirmation"
              element={<CancellationConfirmation />}
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
