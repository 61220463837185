import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Success: React.FC = () => {
  const navigate = useNavigate();
  const [version, setVersion] = React.useState<string>("");

  useEffect(() => {
    const storedVersion = localStorage.getItem("version") || "";
    setVersion(storedVersion);
    if (!storedVersion) {
      console.warn("Version not found in localStorage");
    }

    ReactGA.event({
      category: "User",
      action: "Paid for Chat GPT Plus",
      label: storedVersion,
      value: 8
    });

    localStorage.setItem("hasPaid", "true");
    localStorage.setItem("isLoggedIn", "true");

    window.addEventListener('load', () => {
      navigate("/chat");
    });

    return () => {
      window.removeEventListener('load', () => {
        navigate("/chat");
      });
    };
  }, [navigate]);

  return (
    <div className="bg-white text-black min-h-screen flex items-center justify-center"></div>
  );
};

export default Success;
